.assessment-form-container
{

}

.assessment-form-container .heading
{
    font-family: 'Poppins','sans-sarif';
    font-size: 25px;
    font-weight: normal;
    color: #373737;
}

.assessment-form-container .form-field
{
    margin-top: 10px;
}

.assessment-form-container .form-container .inp-label{
    font-size: 16px;
    font-weight: normal;
    color: #373737;
    margin-bottom: 0;
}

.assessment-form-container .form-container .form-field input,
.assessment-form-container .form-container .form-field select,
.assessment-form-container .form-container .form-field textarea
{
    border: 1px solid #707070;
    padding: 10px 10px;
    margin-bottom: 15px;
}
.assessment-form-container .form-container .date-inp
{
  margin-bottom: 15px;
}

.assessment-form-container .form-container .form-field input::placeholder,
.assessment-form-container .form-container .form-field textarea::placeholder
{
    color :#B5B5B5
}

.assessment-form-container .form-container .form-field input:focus,
.assessment-form-container .form-container .form-field select:focus,
.assessment-form-container .form-container .form-field textarea:focus
{
    outline: none;
}

.assessment-form-container .form-container .form-field textarea
{
  resize: none;
}

.assessment-form-container .form-container .form-field select
{
    appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('../../../public/images/icons/select-arrow.svg') no-repeat;
  background-position: right 8px center;
  background-size: 10px;
  padding-left: 10px;
}

/* .assessment-form-container .form-container input[type=file] {
    display: none;
  }

  .assessment-form-container .form-container .custom-file-input {
    display: inline-block;
    padding: 10px 15px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: white;
    border: 1px solid #373737;
    border-radius: 9px;
    cursor: pointer;
  }

  .assessment-form-container .form-container .custom-file-input-label {
    display: inline-block;
    padding: 10px 15px;
    font-size: 16px;
    font-weight: bold;
    color: black;
    background-color: #D6D6D6;
    border: 1px solid #D6D6D6;
    border-radius: 9px;
    width: fit-content;
    cursor: pointer;
  } */

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.assessment-form-container .react-calendar__tile--now,
.assessment-form-container .react-calendar__tile--now:hover
{
  background-color: #dadada!important;
}


.assessment-form-container .react-calendar__navigation
{
  background-color: #171717;
  margin-bottom:0 ;
 
}
.assessment-form-container .react-calendar__navigation span,
.assessment-form-container .react-calendar__navigation__prev-button,
.assessment-form-container .react-calendar__navigation__arrow
{
  color: white!important;
}

.assessment-form-container .react-calendar__month-view__weekdays
{
  background:radial-gradient(rgb(153, 153, 153),rgba(64, 64, 64, 0.775));
  color: white;
}


.assessment-form-container .react-calendar__tile--active{
  background: none!important;
  background-image: url("../../../public/images/icons/calendar-cir.png")!important;
  background-size: contain!important; 
  background-position: center!important;
  background-repeat: no-repeat!important;
}

.assessment-form-container .file-input-label
{
  padding: .8rem 1.4rem;
  border: 1px solid black;
  font-size: 12px;
  letter-spacing: 2px;
  width: 100%;
  text-transform: uppercase;
  color: black;
  font-weight: 400;
  background: transparent;
  cursor: pointer;
}

.assessment-form-container .submit-btn
{
  font-size: 14px;
  font-weight: 500!important;
}