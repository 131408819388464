.faq-container
{
    
    background: transparent;

}   


.faq-container .text-content .user-img
{
    width: 150px;

}
.faq-container .text-content
{
    
    font-size: 16px;
    font-weight: normal;
    /* color: #373737; */
}

.faq-container .dropdown-box
{
    cursor:pointer;
    width: 100%;
    filter: drop-shadow(0px 3px 9px rgba(0,0,0,0.04));
    background-color: #ffffff;
    padding: 30px 30px;
}

.faq-container .line-seprator{
  width: 77%;
  border: 1px solid rgba(128, 128, 128, 0.199);
  margin: auto;

}

.faq-container .dropdown-box .box-head .title{
    font-weight: 500;
    color:  #515151;
    font-size: 16px;
}

.faq-container .eye-icon
{
    width: 30px;
    height: 30px;
    background-color: black;
    padding: 10px;
}

@keyframes bottomBoxOpen {
    from{
        display: none;
        transform: translateY(-3px);
    }
    to{
       display: block;
       transform: translateY(0px);
    }
}
@keyframes bottomBoxClose {
    from{
        display: block;
        transform: translateY(0px);
    }
    to{
        transform: translateY(-3px);
       display: none;
    }
}