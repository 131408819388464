.GetInTouch-container
{
}

.GetInTouch-container .text-card
{
    width: fit-content;
}
.GetInTouch-container .text-card .body-paragraph
{
    text-transform: uppercase;
    width:'-webkit-fill-available'
}
.GetInTouch-container .text-card .sub-heading
{
    text-transform: lowercase;
    color: #E1E1E1!important;
}


@media(max-width:993px)
{
    .GetInTouch-container .text-card
    {
        width: 100%;
    }
    
}