.common_hero_section {
  color: black;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
 
  width: 100%;
  height: 40rem;
  min-height: 100vh;

}

/* .common_hero_section .bg-effect
{
  background: rgba(167, 166, 166, 0.367);
  background: linear-gradient(0deg, rgba(91, 91, 91, 0.445) 0%, rgba(76, 75, 75, 0.377) 100%);
} */
.common_hero_section .bg-effect
{
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient( rgba(255, 255, 255, 0), #0a1a3b0d,#0818392f,#09193A);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index:-1 ;
}

.common_hero_section .hero-paragraph
{
  width: 70%;
  margin: auto;
}

.common_hero_section .hero-container
{
  border-radius: 20px;
  margin: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.common_hero_section .wrapper
{
  
  border-radius: 20px;
  height: 100%;
}

.bg-video
{
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0);
}

.bg-video video{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.common_hero_section .hero_content_wrapper {
  display: flex;
  padding-left: 0%;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  width: 85%;
}

.common_hero_section .hero_content_wrapper .hero-section-heading,
.common_hero_section .hero_content_wrapper .body-paragraph {
  text-align: center;
}

.common_hero_section .hero_content_wrapper .body-paragraph
{
  width: 80%;
  max-width: 850px;
  color: white;
}

.common_hero_section .hero_content_wrapper .hero-btn
{
  padding: 0.6rem 2.3rem;
  font-size: 14px;
  background: transparent;
  border: 1px solid white;
  font-weight: 700;
  color: white;
  font-weight: bold;
  letter-spacing: 1px;
  border-radius: 29px;
  transition: .3s;
}
.common_hero_section .hero_content_wrapper .hero-btn:hover
{
  background: white;
  border: 1px solid white;
  color: #8740A5;
}
.common_hero_section .heading-color-change
{
  color: #283285;

}
.common_hero_section .service-form
{
  background-color: white;
  border-radius: 10px;
}
.common_hero_section .service-form .service-from-btn
{
  width: fit-content;
  margin-top: 25px;
}
.common_hero_section .service-form input
{
  border: none;
  border-bottom: 1px solid rgb(0, 0, 0);
  background:transparent;
  margin-top: 20px;

}
.common_hero_section .service-form input:focus
{
  outline: none;
}

.common_hero_section .hero-text-content .body-paragraph 
{
  /* width: 50%; */
  text-align: center;
}

.common_hero_section .verticle-text
{
    writing-mode: vertical-rl; /* For vertical text from right to left */
    text-orientation: mixed; /* This property is added for compatibility */
    white-space: nowrap; /* Prevent text from wrapping to a new line */
    transform: rotate(180deg); /* Rotate the text 180 degrees to invert the direction */
    font-size: 22px;
    letter-spacing: 5px;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 700;
    font-family: "Playfair Display"!important;
}

.common_hero_section .icon-container img{
  width: 30px;
  height: 30px;
}

@media(max-width :760px)
{
  .h-cus-auto
  {
    height: auto!important;
  }
  
  .common_hero_section .hero-paragraph
  {
    width: 100%;
    margin: auto;
  }
}

@media(max-width : 660px)
{
  .common_hero_section .hero-text-content .body-paragraph 
  {
    width : 100%;
  }
 
}

@media(max-width : 400px)
{
  .common_hero_section .hero_content_wrapper .hero-btn
  {
    padding: 0.6rem 1.8rem;
    font-size: 15px;
  }
  .common_hero_section .icon-container img{
    width: 25px;
    height: 25px;
  }
  .common_hero_section .verticle-text
{
    font-size: 18px;
    letter-spacing: 5px;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 700;
    font-family: "Playfair Display"!important;
}
}