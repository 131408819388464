.OccupiedStagging-container
{
    /* background-color: black; */
}

.OccupiedStagging-container .card-box
{
    /* padding: 20px; */
    height: 100%;
    
}

.OccupiedStagging-container .card-box .card-para
{
    height: 280px;
    overflow-y: auto;
}
