.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.5); */
    display: flex;
    justify-content: center;
    align-items: start;
    z-index: 100;
  }
  
  .modalBackground .popup-inner {
    position: sticky;
    top: 50vh;
    max-width: 700px;
    margin: 0 auto;
    border-radius: 16px;
    filter: drop-shadow(0px 3px 20.5px rgba(0, 0, 0, 0.11));
    /* background-color: #ffffff; */
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    background: #a7a6a65e;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    z-index: 1000;
  }
  .popup .img-container
  {
    max-height: 90vh;
    overflow: hidden;
  }

 .popup-inner .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    border: none;
    background: none;
    font-size: 20px;
    color: #555;
    padding: 0;
  }
  
 .popup-inner .close-btn:hover {
    color: #333;
  }
  
@media(max-width:750px)
{
  .modalBackground .popup-inner {
    
    max-width: 400px;
    
  }
}