.ContactPageForm-container
{
    
}

.ContactPageForm-container .heading-line
{
    height: 0;
    width:25%;
    border-bottom: 1px solid black;
}

.ContactPageForm-container .input-field
{
    border: none;
    border-bottom: 1px solid black;
    padding-bottom: 10px;
    resize: none;
    margin-bottom: 22px;
}
.ContactPageForm-container .input-field:focus
{
    outline: none;
}

.ContactPageForm-container .contact-inp-form
{
    padding-right: 50px;
}

@media(max-width:992px)
{
    .ContactPageForm-container .heading-line
    {
        display: none;
    }
    .ContactPageForm-container .input-field
    {
        
        margin-bottom: 12px;
    }
    .ContactPageForm-container .contact-inp-form
    {
        padding-right: 0px;
    }
}