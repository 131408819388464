.DecoratingInteriorDesign-container
{

}

.DecoratingInteriorDesign-container .heading-sec .body-heading
{
    width: 90%;
}

.DecoratingInteriorDesign-container .img-container
{
    height: 550px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}