.PortfolioSection-container
{
    background-color: transparent;
}
.PortfolioSection-container .container
{
    
    transform: translateY(-300px);
}

.PortfolioSection-container .active-btn
{
    padding: .8rem 2rem;
    border: 1px solid black;
    font-size: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
    background: black;
    color: white;
    font-weight: 400;
    font-family: "Montserrat", sans-serif!important;
  
}


.PortfolioSection-container .box
{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 400px;
    width: 100%;
    
}
.PortfolioSection-container .i0-box
{
    
}
.PortfolioSection-container .i1-box
{
 height: 450px;   
}

.PortfolioSection-container .genral-btn-light,
.PortfolioSection-container .active-btn
{
    width: 100%;
}

@media(max-width:768px)
{
    .PortfolioSection-container .container
    {
        
        transform: translateY(-150px);
    }
    
}
@media(max-width:458px)
{
    .PortfolioSection-container .container
    {
        
        transform: translateY(0);
    }
    
}