/* Modal.css */
.PortfolioModal-container {
    position: fixed; /* Changed from absolute to fixed */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out, visibility 0.5s;
  }
  
  .PortfolioModal-container.show {
    opacity: 1;
    visibility: visible;
  }
  
  .PortfolioModal-container .modal-main {
    position: fixed; /* Changed from absolute to fixed */
    background: transparent;
    color: black;
    width: fit-content;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
  }
  
  .PortfolioModal-container .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.472);
  }
  
  .PortfolioModal-container .modal-main .close-modal {
    padding: 5px 11px;
    border: none;
    background-color: rgb(240, 74, 74);
    border-radius: 5px;
    position: absolute;
    top: 10px;
    right: 60px;
    color: white;
  }
  
  .PortfolioModal-container .arrow-btn {
    background: rgba(255, 255, 255, 0.297);
    border-radius: 50%;
    margin: 0 3px;
    border: none;
  }
  
  .PortfolioModal-container .arrow-btn img {
    width: 30px;
    height: 35px;
  }
  
  .PortfolioModal-container .modal-img {
    width: 400px;
    height: 500px;
  }
  
  .PortfolioModal-container .iframe-vid {
    width: 80vw;
    height: 70vh;
    min-height: 400px;
  }
  
  .PortfolioModal-container .swiper {
    max-width: 400px;
  }
  
  body.active-modal {
    overflow-y: hidden;
  }
  
  @media(max-width: 450px) {
    .PortfolioModal-container .modal-main {
      width: 100%;
    }
  
    .PortfolioModal-container .modal-img {
      height: 400px;
      max-height: 80vh;
      width: 300px;
    }
  
    .PortfolioModal-container .swiper {
      width: 300px;
    }
  }
  